import { Component } from '@angular/core'
import { BaseArticleComponent } from 'src/app/shared/components/base-article-component/base-article-component'
import { AssetSale } from '../asset-sale-article.model'
import { AsyncPipe } from '@angular/common'
import { AssetSaleViewComponent } from '../../../../shared/components/asset-sale-view/asset-sale-view.component'
import { NgScrollbarModule } from 'ngx-scrollbar'

@Component({
    selector: 'app-asset-article',
    templateUrl: './asset-article.component.html',
    styleUrls: ['./asset-article.component.scss'],
    imports: [NgScrollbarModule, AssetSaleViewComponent, AsyncPipe]
})
export class AssetArticleComponent  extends BaseArticleComponent<AssetSale> {
    constructor() {
        super()
        this.area =  'data/asset-sales'
    }
}
